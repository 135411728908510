import axios from 'axios'
import _find from 'lodash/find'
import moment from 'moment'
import config from '@/config'
import OffersStatusEnum from '@/constants/OffersStatusEnum'

const getDefaultState = () => ({
  loading: true,
  loadingStatuses: false,
  loadingBudget: false,
  homepageCalculations: {
    accepted_offers_count: 0,
    accepted_offers_value: 0,
    active_suppliers_count: 0,
    statuses: [
      { status: 1, ukupno: 0 },
      { status: 2, ukupno: 0 },
      { status: 3, ukupno: 0 },
      { status: 4, ukupno: 0 },
    ],
  },
  budgetOverview: {
    all_budget_sum: 0,
    budgets_total_spent: 0,
    cats_count: 0,
    budgets_by_cat_count: 0,
  },
  chartsFilters: {
    dates: {
      dateFrom: moment().subtract(1, 'months').format(config.dateFormat),
      dateTo: moment().format(config.dateFormat),
    },
    groupBy: 1,
    category: 0,
    status: OffersStatusEnum.CONFIRMED,
    supplier: 0,
    noValue: 1,
  },
  chartsData: {
    count_purchases_by_period: {
      data: [],
      labels: [],
    },
    total_by_period_and_supplier: {
      data: [],
      labels: [],
      colors: [],
    },
    total_consumption_by_period: {
      data: [],
      labels: [],
    },
    count_purchases_by_category: {
      data: [],
      labels: [],
      colors: [],
    },
  },
})

const state = getDefaultState()

const actions = {
  async fetchCountPurchasesByStatus({ commit, rootGetters }) {
    try {
      commit('setLoadingStatuses')
      const response = await axios.get(`statistics/calculations/homepageCalculations?company_id=${rootGetters['user/getUserCompanyId']}`)
      commit('setHomepageCalculations', response.data)
      commit('setLoadingStatuses', false)

      return response
    } catch (error) {
      commit('setLoadingStatuses', false)

      return error
    }
  },

  async fetchBudgetOverview({ commit }) {
    try {
      commit('setLoadingBudget')
      const response = await axios.get('statistics/budgets/get')
      commit('setBudgetOverview', response.data)
      commit('setLoadingBudget', false)

      return response
    } catch (error) {
      commit('setLoadingBudget', false)

      return error
    }
  },

  async filterStatistics({ commit, rootGetters }) {
    try {
      commit('setLoading')
      const response = await axios.get(`statistics/global/get?company_id=${rootGetters['user/getUserCompanyId']}&date_from=${state.chartsFilters.dates.dateFrom}&date_to=${state.chartsFilters.dates.dateTo}&groupby=${state.chartsFilters.groupBy}&category=${state.chartsFilters.category}&status=${state.chartsFilters.status}&supplier=${state.chartsFilters.supplier}&no_value=${state.chartsFilters.noValue}`)
      commit('setChartsData', response.data)
      commit('setLoading', false)

      return response
    } catch (error) {
      commit('setLoading', false)

      return error
    }
  },
}

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },

  setHomepageCalculations(state, data) {
    state.homepageCalculations = data
  },

  setCategoryFilter(state, categoryId) {
    if (!categoryId) return
    state.chartsFilters.category = categoryId
  },

  setStatusFilter(state, status) {
    if (!status) return
    state.chartsFilters.status = status
  },

  setCategoryFilterToDefaultValue(state) {
    state.chartsFilters.category = 0
  },

  setSupplierFilter(state, supplierId) {
    if (!supplierId) return
    state.chartsFilters.supplier = supplierId
  },

  setSupplierFilterToDefaultValue(state) {
    state.chartsFilters.supplier = 0
  },

  setChartsData(state, data) {
    state.chartsData = data
  },

  setBudgetOverview(state, payload) {
    state.budgetOverview = payload
  },

  setLoading(state, loading = true) {
    state.loading = loading
  },

  setLoadingStatuses(state, loading = true) {
    state.loadingStatuses = loading
  },

  setLoadingBudget(state, loading = true) {
    state.loadingBudget = loading
  },
}

const getters = {
  getHomepageCalculations: state => state.homepageCalculations,
  findPurchaseCountByStatus: state => status => {
    const count = _find(state.homepageCalculations.statuses, ['status', status])

    return count ? count.ukupno : 0
  },
  getChartsFilters: state => state.chartsFilters,
  getStatisticByKey: state => key => state.chartsData[key],
  getStatisticLoading: state => state.loading,
  getLoadingStatuses: state => state.loadingStatuses,
  getLoadingBudget: state => state.loadingBudget,
  getBudgetOverview: state => state.budgetOverview,
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
