import Vue from 'vue'

const config = {
  dateFormat: 'YYYY-MM-DD',
  dateFormatPreview: 'DD.MM.YYYY',
  firstDayOfWeek: 1,
}

const currency = 'RSD'

function weekDayFormat(isoDate) {
  return Vue.prototype.$moment(isoDate).format('dd')
}

function monthFormat(isoDate) {
  return Vue.prototype.$moment(isoDate).format('MMM')
}

function headerDateFormat(isoDate) {
  return Vue.prototype.$moment(isoDate).format('MMMM YYYY')
}

function titleDateFormat(isoDate) {
  return Vue.prototype.$moment(isoDate).format('ddd DD. MMM')
}

export {
  headerDateFormat,
  titleDateFormat,
  weekDayFormat,
  monthFormat,
  currency,
}

export default config
