import srLatin from 'vee-validate/dist/locale/sr_Latin.json'

export default {
  'Welcome back!': 'Dobrodošli nazad!',
  appDescription: 'Jednostavna komunikacija sa sopstvenim dobavljačima, slanje zahteva za ponudom, jasan pregled svih pristiglih ponuda, odabir najbolje, analitika, formiranje izveštaja. Sve na jednom mestu.',
  'Forgot Password?': 'Zaboravljena lozinka?',
  'Do not have an account?': 'Nemate nalog?',
  'Create an account': 'Registrujte se',
  'Make an account': 'Napravi nalog',
  'Already have an account?': 'Već imate nalog?',
  'Sign in instead': 'Ulogujte se',
  'I agree to': 'Prihvatam',
  'Remember Me': 'Zapamti me',
  Login: 'Prijavi se',
  Logout: 'Odjavi se',
  Home: 'Početna',
  Add: 'Dodaj',
  Cancel: 'Odustani',
  Delete: 'Obriši',
  Edit: 'Izmeni',
  View: 'Pogledaj',
  Save: 'Sačuvaj',
  Send: 'Pošalji',
  Profile: 'Profil',
  Confirm: 'Potvrdi',
  Description: 'Opis',
  Category: 'Kategorija',
  Company: 'Kompanija',
  Files: 'Dodatni dokumenti',
  PIB: 'PIB',
  Accept: 'Prihvati',
  Reject: 'Odbij',
  'Deadline for response': 'Rok za odgovor',
  'Delivery deadline': 'Rok za isporuku',
  'Select status': 'Izaberite status',
  'Select category': 'Izaberite kategoriju',
  'Delivery date': 'Datum isporuke',
  Back: 'Nazad',
  'No data available': 'Nema dostupnih podataka',
  'Terms of Condition': 'Uslovi korišćenja',
  Close: 'Zatvori',
  Yes: 'Da',
  No: 'Ne',
  Status: 'Status',

  purchaseStatuses: {
    Active: 'Aktivni',
    Valuation: 'Valuacija',
    Closed: 'Zatvoreni',
    Canceled: 'Stornirani',
  },
  Active: 'Aktivan',
  Closed: 'Zatvoren',
  'Cancel request': 'Storniraj zahtev',

  SuppliersEmptyText: '',
  Suppliers: 'Dobavljači',
  Supplier: 'Dobavljač',
  'Select supplier': 'Izaberite Dobavljača',
  Categories: 'Kategorije',
  'Categories overview': 'Kategorije',
  'All suppliers': 'Dobavljači',

  Procurements: 'Nabavke',
  Request: 'Zahtev',
  Offers: 'Poslato dobavljačima/Pristigle ponude',
  Offer: 'Ponuda',
  'New request': 'Nov zahtev',
  'Requests overview': 'Pregled zahteva',
  'Closed requests': 'Zatvoreni zahtevi',
  'Confirmed requests': 'Potvrdjeni zahtevi',
  'Privacy policy': 'Uslove korišćenja',
  'Format exm.': 'Format npr: +381652013555',

  successRegistrationMessage: 'Uspešno ste obavili registraciju. Proverite Vaš email poslali smo vam link za potvrdu registracije.',

  'New category': 'Nova kategorija',
  'Add new category': 'Dodaj novu kategoriju',
  'Edit category': 'Izmena kategorije',
  'Confirm deletion': 'Potvrda brisanja',
  confirmDeletionQuestion: 'Ovu akciju ne možete poništiti.',
  'Yes, delete it': 'Da, obriši',
  'No, just kidding': 'Odustani',
  'New supplier': 'Novi dobavljač',
  'Add new supplier': 'Dodaj novog dobavljača',
  'Edit supplier': 'Izmena dobavljača',
  'New contact person': 'Nova kontakt osoba',
  'Save contact person': 'Snimi kontakt osobu',
  'Update contact person': 'Izmeni kontakt osobu',
  Price: 'Cena',
  'Offer Pending': 'Na čekanju',
  'Offer Link opened': 'Otvoren link',
  'Offer Offer sent': 'Ponuda poslata',
  'Offer Accepted': 'Ponuda prihvaćena',
  'Offer Confirmed': 'Ponuda potvrđena',
  'Offer Rejected': 'Ponuda odbijena',
  'Purchase Active': 'Aktivan',
  'Purchase Valuation': 'Valuacija',
  'Purchase Closed': 'Zatvoren',
  'Purchase Canceled': 'Storniran',
  'Purchase Active Home': 'Aktivnih zahteva',
  'Purchase Valuation Home': 'Zahteva u valuaciji',
  'Purchase Closed Home': 'Zatvorenih zahteva',
  'Purchase Canceled Home': 'Storniranih zahteva',
  'Bid value Home': 'Ukupna vrednost svih nabavki',
  'Accepted offers Home': 'Prihvaćenih ponuda',
  'Active suppliers Home': 'Aktivnih dobavljača',
  Note: 'Napomena',
  Quantity: 'Količina',
  Accepted: 'Prihvaćeno',
  of: 'od',
  Undo: 'Opozovi',
  'Confirm request': 'Potvrdi zahtev',
  'Request is closed': 'Zahtev je zatvoren',
  'Request is canceled': 'Zahtev je storniran',
  'Request sent for verification': 'Zahtev je poslat proveru',
  'By month': 'Po mesecu',
  'By week': 'Po sedmici',
  'By category': 'Po kategoriji',
  'Number of purchases by month': 'Broj nabavki za period',
  'Number of purchases by week': 'Broj nabavki za period',
  'Number of purchases by category': 'Broj nabavki po kategoriji',
  'Number of purchases by supplier': 'Broj realizovanih nabavki po dobavljaču',
  'Consumption by month': 'Vrednost nabavki za period',
  'Consumption by week': 'Vrednost nabavki za period',
  'Minimum and maximum value by category': 'Vrednost nabavki po kategoriji (min-max)',
  'Minimum and maximum value by month': 'Vrednost nabavki za period (min-max)',
  'Total consumption by category': 'Vrednost nabavki po kategoriji (period)',
  'Chart type': 'Tip grafikona',
  'View type': 'Vrsta prikaza',
  'Minimum and maximum value for purchase': 'Minimalna i maksimalna vrednost za nabavku',
  'Procurement number': 'Broj nabavke',
  'Choose number of procurement': 'Izaberi broj nabavke',
  'Add supplier': 'Dodaj dobavljača',
  'View suppliers': 'Vidi dobavljače',
  'There are no suppliers in this category': 'U ovoj kategoriji trenutno nema dobavljača',
  'View categories': 'Vidi kategorije',
  'User management': 'Upravljanje korisnicima',
  Users: 'Korisnici',
  'Create user': 'Kreiraj korisnika',
  'All users': 'All users',
  'Are you confirming the deletion of the user': 'Da li potvrđujete brisanje korisnika',
  'Edit user': 'Izmena korisnika',
  'Can confirm purchase': 'Može da potvrdi nabavku',
  'Select role': 'Izaberi rolu',
  'Are you sure you want to confirm the request': 'Da li ste sigurni da želite da potvrdite zahtev',
  'Are you sure you want to submit a request for approval': 'Da li ste sigurni da želite da pošaljete zahtev proveru',
  'Send for approval': 'Pošalji na proveru',
  'Purchase management': 'Upravljanje nabavkama',
  'Purchase confirmation': 'Potvrda nabavki',
  'Date from': 'Datum od',
  'Date to': 'Datum do',
  'Transportation included': 'Prevoz uključen',
  'Transportation not included': 'Prevoz nije uključen',
  'Maximum amount to confirm': 'Maksimalan iznos za potvrdu',
  Budget: 'Budžet',
  Budgets: 'Budžeti',
  budgetFor: '{categories} od {totalCategories} kategorija',
  'Budgets overview': 'Pregled budžeta',
  'Create budget': 'Kreiraj budžet',
  'Edit budget': 'Izmena budžeta',
  'You have successfully created a budget': 'Uspešno ste kreirali budžet',
  'Select an existing contact person': 'Izaberi postojeću kontakt osobu',
  'Add category': 'Dodaj kategoriju',
  'Can view the budget': 'Može da vidi budžet',
  'Duplicate request': 'Ponovi zahtev',
  Articles: 'Artikli',
  Name: 'Naziv',
  'Order number': 'Redni br.',
  'Unit price': 'Jedinična cena',
  'Quantity requested': 'Tražena količina',
  'Quantity offered': 'Ponudjena količina',
  Projected: 'Projektovano',
  Spent: 'Utrošeno',
  'Number / Value': 'Broj / Vrednost',
  Amount: 'Iznos',
  'Comprehensive data from the platform': 'Sveobuhvatni podaci sa platforme',

  chartsFilters: {
    title: 'Filteri',
    buttonText: 'Primeni filter',
    offersStatuses: {
      acceptedOffers: 'Prihvaćene ponude',
      declinedOffers: 'Odbijene ponude',
    },
    noValues: {
      numberOffer: 'Broj ponuda',
      valueOffer: 'Vrednost ponuda',
    },
  },

  columns: {
    id: 'ID',
    request_number: 'ZAHTEV BR.',
    name: 'NAZIV',
    actions: 'AKCIJE',
    pib: 'PIB',
    status: 'STATUS',
    category: 'KATEGORIJA',
    created_at: 'KREIRAN',
    date_for_offer: 'ROK ZA ODGOVOR',
    email: 'EMAIL',
    role: 'ROLA',
    budget: 'BUDŽET',
    spent: 'POTROŠENO',
    date_from: 'DATUM OD',
    date_to: 'DATUM DO',
  },

  fields: {
    name: 'Naziv',
    email: 'Email',
    password: 'Lozinka',
    companyName: 'Naziv kompanije',
    pib: 'PIB',
    nameAndSurname: 'Ime i prezime',
    phone: 'Telefon',
    passwordConfirm: 'Potvrda lozinke',
    privacyPolicy: 'Uslovi korišćenja',
    description: 'Opis',
    contactPerson: 'Kontakt osoba',
    category: 'Kategorija',
    categories: 'Kategorije',
    file: 'Datoteke',
    price: 'Cena',
    date_for_offer: 'Rok za odgovor',
    date_for_delivery: 'Rok za isporuku',
    request_description: 'Opis zahteva',
    quantity: 'Količina',
    unit: 'Jedinica',
    city: 'Grad',
    companyAddress: 'Adresa kompanije',
    role: 'Rola',
    address: 'Adresa kompanije',
    category_id: 'Kategorija',
    budget: 'Budžet',
    date_from: 'Datum od',
    date_to: 'Datum do',
    amount: 'Iznos',
  },
  validation: {
    ...srLatin.messages,
    ...{
      required_checkbox: 'Polje {_field_} je obavezno',
      array_length: 'Polje {_field_} ne sme biti duže od {array_length} karaktera',
      phone_validation: 'Format polja {_field_} nije validan. Validan format +381652013555',
    },
  },
}
