import Vue from 'vue'

const isLoggedIn = () => !!localStorage.getItem(Vue.prototype.$auth.options.tokenDefaultKey)

const removeAccessToken = () => {
  const token = localStorage.getItem(Vue.prototype.$auth.options.tokenDefaultKey)

  if (token) {
    localStorage.removeItem(Vue.prototype.$auth.options.tokenDefaultKey)
  }
}

export {
  isLoggedIn,
  removeAccessToken,
}
